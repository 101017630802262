body {
    background-color: whitesmoke;
}

.dropzone {
    height: 300px;
    flex: 1;
    -ms-flex: 1;
    display: flex;
    flex-direction: column;
    -ms-flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    justify-content: center;
}

:root {
    --toastify-color-info: #32A5C4;
}

@keyframes slide-out-right {
    0% {
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        transform: translateX(1000px);
        opacity: 0;
    }
}

.slide-out-right {
    animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

@keyframes shake-horizontal {

    0%,
    100% {
        transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70% {
        transform: translateX(-10px);
    }

    20%,
    40%,
    60% {
        transform: translateX(10px);
    }

    80% {
        transform: translateX(8px);
    }

    90% {
        transform: translateX(-8px);
    }
}


.shake-horizontal {
    animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

.report {
    overflow: auto;
}

.MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.3 !important;
}

.section .section-content .separator~:empty {
    padding-top: 0px !important;
}

@media print {
    .no-print {
        display: none !important;
    }

    .MuiPaper-root {
        box-shadow: none !important;
    }

    .report {
        overflow: inherit;
    }

    .pagebreak {
        clear: both;
        page-break-after: always;
        display: block;
    }
}

/* fallback */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(./fonts/MaterialIcons-Regular.woff2) format('woff2');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

.tinymce-inline-body p {
    margin: 0
}

.tinymce-inline-body img {
    max-width: 100px
}

.tox-promotion {
    display: none;
}

.tox-tinymce {
    border: none !important;
}

@media print {
    .tox-editor-header {
        display: none !important;
    }
}